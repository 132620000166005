import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import HeaderSection from "../components/headers/light.js";
import Footer from "../components/footers/Footer";
import AlternatingImages from "../components/features/AlternatingImages";

import CloudImage from "../images/trustek/cloud-2.gif"
import AIImage from "../images/trustek/ai.gif"
import CyberImage from "../images/trustek/cyber-min.gif"
import MobileImage from "../images/trustek/mobile-min.gif"
import NetworkingImage from "../images/trustek/networking-min.gif"
import BIImage from "../images/trustek/data-tech-min.gif"
import SoftwareImage from "../images/trustek/software-min.gif"
import RPAImage from "../images/trustek/rpa-min.gif"
import CRMImage from "../images/trustek/crm.gif"

const TechnologiesPage = () => {
  return (
    <AnimationRevealPage>
      <HeaderSection />
      <AlternatingImages 
        heading = "Technologies"
        description = "At our firm, we offer comprehensive technology solutions that encompass consultations, support, and staffing services. We undertake development projects, leveraging our in-house expertise to create robust and innovative products. With a focus on delivering high-quality solutions, we ensure that our clients receive end-to-end support and achieve their desired outcomes."
        description2=""
        cards = {[{
            imageSrc:CloudImage,
            subtitle: "",
            title: "Cloud Computing Solutions",
            description:"Our consultancy offers expert guidance and implementation of cloud computing solutions. We assist businesses in leveraging the power of cloud technologies such as AWS, Azure, and Google Cloud Platform to enhance scalability, flexibility, and cost-efficiency. From cloud strategy development to migration and optimization, we ensure seamless adoption of cloud infrastructure and services tailored to meet our clients' specific needs.",
          },
          {
            imageSrc:AIImage,
            subtitle: "",
            title: "Artificial Intelligence and Machine Learning",
            description:"Our team of AI and ML specialists provides cutting-edge solutions that harness the potential of artificial intelligence and machine learning technologies. We offer consultation, development, and deployment of intelligent systems, including natural language processing, computer vision, predictive analytics, and recommendation engines. By leveraging Python, TensorFlow, and PyTorch, we help our clients automate processes, gain valuable insights from data, and drive innovation in their respective industries.",
          },
          {
            imageSrc:CyberImage,
            subtitle: "",
            title: "Cybersecurity and Risk Management",
            description:"Our consultancy places a strong emphasis on cybersecurity and risk management services to protect businesses from evolving threats. We conduct thorough assessments, develop robust security strategies, and implement measures to safeguard critical assets and data. From network security and vulnerability assessments to incident response planning and employee training, we ensure our clients have comprehensive security measures in place to mitigate risks and maintain data integrity.",
          },
          {
            imageSrc:MobileImage,
            subtitle: "",
            title: "Mobile and Web Application Development",
            description:"With expertise in popular programming languages and frameworks such as Java, Swift, React Native, and Angular, our consultancy offers end-to-end mobile and web application development services. We design and build intuitive, user-friendly applications that align with our clients' goals and target audience. Our experienced developers ensure seamless functionality, performance optimization, and responsive design, resulting in high-quality applications that enhance customer engagement and drive business growth.",
          },
          {
            imageSrc:NetworkingImage,
            subtitle: "",
            title: "IT Infrastructure and Networking",
            description:"Our consultancy provides comprehensive IT infrastructure and networking services to optimize technology environments. From network design and implementation to server management and virtualization, we ensure a robust and secure infrastructure that supports efficient operations. Our expertise covers areas such as data centers, cloud integration, storage solutions, and network monitoring, enabling our clients to achieve reliable and scalable IT environments.",
          },
          {
            imageSrc:BIImage,
            subtitle: "",
            title: "Data Analytics and Business Intelligence",
            description:"We help businesses derive actionable insights from their data through our data analytics and business intelligence services. Our team employs advanced analytics tools and techniques using languages like R and Python to analyze, visualize, and interpret data, empowering clients to make data-driven decisions. From data warehousing and reporting to predictive modeling and dashboard development, we enable our clients to gain a competitive edge by unlocking the value hidden in their data.",
          },
          {
            imageSrc:SoftwareImage,
            subtitle: "",
            title: "Software Development and Customization",
            description:"Our consultancy specializes in software development and customization services tailored to our clients' specific needs. We have expertise in multiple programming languages and frameworks such as Java, C#, .NET, and Ruby on Rails to create robust, scalable, and feature-rich software solutions. Our team collaborates closely with clients, understanding their requirements, and delivering high-quality software products that align with their business objectives.",
          },
          {
            imageSrc:RPAImage,
            subtitle: "",
            title: "Robotic Process Automation (RPA)",
            description:"Our consultancy excels in Robotic Process Automation (RPA) solutions to streamline business processes and enhance operational efficiency. Leveraging tools such as UiPath, Automation Anywhere, and Blue Prism, we automate repetitive tasks, data entry, and rule-based processes. By implementing RPA, we enable our clients to optimize productivity, reduce errors, and free up valuable human resources for more strategic initiatives.",
          },
          {
            imageSrc:CRMImage,
            subtitle: "",
            title: "Customer Relationship Management (CRM) Tools",
            description:"We offer expertise in implementing and customizing CRM tools such as Salesforce, Microsoft Dynamics 365, and HubSpot to optimize customer interactions and drive sales growth. Our consultancy helps businesses effectively manage customer relationships, track sales pipelines, and automate marketing campaigns. With CRM implementation and integration, we enable our clients to enhance customer satisfaction, improve lead conversion, and foster long-term customer loyalty.",
          },
        ]}
      />
      {/* <FAQ /> */}
      <Footer/>
    </AnimationRevealPage>
  );
};

export default TechnologiesPage