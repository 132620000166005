import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import HeaderSection from "../components/headers/light.js";
import Footer from "../components/footers/Footer";
import Careers from "../components/cards/Careers";


const CareersPage = () => {
  return (
    <AnimationRevealPage>
      <HeaderSection />
      <Careers/>
      {/* <FAQ /> */}
      <Footer/>
    </AnimationRevealPage>
  );
};

export default CareersPage