import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import HeaderSection from "../components/headers/light.js";
import Footer from "../components/footers/Footer";
import AlternatingImages from "../components/features/AlternatingImages";
import StaffingImage from "../images/trustek/anim-staffing.svg";
import WebAppdevelopmentImage from "../images/trustek/Web-App-Development.gif";
import DevopsImage from "../images/trustek/DevOps.gif"
import DataImage from "../images/trustek/Data.gif"

const ServicesPage = () => {
  return (
    <AnimationRevealPage>
      <HeaderSection />
      <AlternatingImages 
        heading = "Services"
        description = "We provide a wide range of services to help businesses and organizations enhance their technological capabilities. Our team of experts specializes in technology strategy and planning, software and hardware selection, IT project management, data analytics and business intelligence, cybersecurity and risk management, cloud computing and infrastructure design, digital transformation, and IT outsourcing."
        description2="We work closely with our clients to understand their unique needs and goals, evaluate their existing systems and processes, and develop customized solutions to optimize their IT infrastructure and drive growth and innovation. Our team also provides training and support to ensure seamless implementation and ongoing maintenance of these solutions, keeping our clients ahead of the curve in the ever-evolving technology landscape."
        cards = {[{
            imageSrc:StaffingImage,
            subtitle: "Exclusive",
            title: "Staffing Services",
            description:"We offer comprehensive staffing solutions for companies seeking to hire talented IT professionals. Our staffing services include contract, contract-to-hire, and direct placement options, and we specialize in sourcing candidates with diverse skill sets and experience levels. We work closely with our clients to understand their unique staffing needs and provide personalized support throughout the hiring process.",
          },
          {
            imageSrc: WebAppdevelopmentImage,
            subtitle: "Exclusive",
            title: "Web and App Development",
            description:"Our software development services include utilizing popular programming languages and frameworks to build custom mobile and web applications that meet the specific needs of our clients. We are experienced in using languages such as Java, Python, and Swift, and frameworks such as React, Angular, and Node.js. Our team of developers is skilled in delivering high-quality software solutions that are user-friendly and scalable, ensuring a smooth user experience.",
          },
          {
            imageSrc:DevopsImage,
            subtitle: "Exclusive",
            title: "DevOps Services",
            description:"Our DevOps services are designed to optimize the software development lifecycle, and we specialize in using tools such as Kubernetes and Docker to improve the efficiency and scalability of our clients' applications. Our team of experts is experienced in building and deploying containerized applications using these technologies, enabling faster delivery and improved reliability. We work closely with our clients to customize our DevOps solutions to their specific needs and ensure seamless integration with their existing systems.",
          },
          {
            imageSrc:DataImage,
            subtitle: "Exclusive",
            title: "Data Engineering",
            description:"We provide comprehensive data services to help businesses harness the power of their data. Our offerings include data analysis, data integration, and data visualization, enabling organizations to make informed decisions based on actionable insights. With our expertise in data management and advanced analytics techniques, we empower our clients to unlock the full potential of their data assets.",
          },
        ]}
      />
      {/* <FAQ /> */}
      <Footer/>
    </AnimationRevealPage>
  );
};

export default ServicesPage