import './App.css';

import React from "react";
import GlobalStyles from './styles/GlobalStyles';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import TrustekLandingPage from "./pages/TrustekLandingPage";

import ServicesPage from "./pages/Services";
import TechnologiesPage from "./pages/Technologies";
import Careers from "./pages/CareersPage";
import ContactUsPage from "./pages/ContactUs";
import PrivacyPage from "./pages/PrivacyPolicy";
import ToS from "./pages/TermsOfService";
import EmployeeReferral from "./pages/EmployeeReferral";
import NotFound from "./pages/NotFound";


function App() {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TrustekLandingPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/technologies" element={<TechnologiesPage />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/refer" element={<EmployeeReferral />} />
          <Route path="/tos" element={<ToS />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
