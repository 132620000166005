import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderSection from "../components/headers/light.js";
import Footer from "../components/footers/Footer";
import { SectionHeading } from "../components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const EmployeeReferral = ({ headingText = "Employee Referral" }) => {
  const currentYear = new Date().getFullYear();
  return (
    <AnimationRevealPage>
      <HeaderSection />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>

            <p>Last updated: Jan 1, {currentYear}</p>

            <p>Trustek acknowledges that many times the best employees can be found from the candidate referrals of our own employees.  As indicated in our employee handbook, Trustek has an existing employee referral bonus program in order to promote these candidate referrals.  Since the program’s implementation in Trustek, we have encouraged our employees to assist us in attracting top talent, by offering a referral bonus ($1000.00) to any employee who refers a candidate, who is hired for regular employment.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

export default EmployeeReferral