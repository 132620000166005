import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";
import { Container, ContentWithPaddingXl } from "../misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;



const Careers = ({
  subheading = "Careers",
  heading = "Want to join us on our journey to delivery Excellence?",
  description = "Send your resume to hr@trustek.com and we will reachout to you as soon as possible.",
  faqs = [
    {
      question: "Software Engineer - Java",
      answer:"We are looking for a skilled java software engineer to join our software development team. In this role, you will be required to participate in all stages of the software product development life cycle. This may include analyzing system and user requirements, designing software flowcharts, writing clean Java code, troubleshooting bugs, and running tests to improve system functionality.",
      answer2:"To ensure success as a java software engineer, you should have intimate knowledge of Java coding and development, excellent software troubleshooting skills, and the ability to manage projects. Ultimately, a top-notch java software engineer can work together with the development team to create high-quality software applications that meet the needs of the user."
    },
    {
      question: "Software Engineer - .Net",
      answer:"We are seeking a skilled .NET developer to join our team and contribute to the development of software using the .NET framework. In this role, you will be responsible for creating applications from the ground up, configuring existing systems, and providing user support.",
      answer2:"To excel in this position, you must possess the ability to write functional code and have a keen eye for identifying and resolving defects. Collaboration and effective communication are essential, as you will be working closely with internal teams. If you have a genuine passion for the .NET framework and possess a strong understanding of software design and architecture, we would like to meet you."
    },
    {
      question: "Application/Network Security Engineer",
      answer:"We are looking for a capable System Security Engineer, who enjoys security work and possesses both deep and wide expertise in the security space. You will make things more secure by protecting system boundaries, keeping computer systems and network devices hardened against attacks and securing highly sensitive data. Qualified candidates will have a background in Security or Systems Engineering.",
      answer2:"Security Engineers are the first line of defense against malicious actors in the IT industry. They screen their company’s critical IT infrastructure for weaknesses and create robust countermeasures to prevent future incidents. Security Engineers also train employees on security best practices and advice management on investments to safeguard the company’s computer and network systems."
    },
    {
      question: "Big Data Engineer",
      answer:"We are looking for a Big Data Engineer that will work on the collecting, storing, processing, and analyzing of huge sets of data. The primary focus will be on choosing optimal solutions to use for these purposes, then maintaining, implementing, and monitoring them. You will also be responsible for integrating them with the architecture used across the company.",
      answer2:"A successful big data engineer must have solid data processing experience and a willingness to learn new tools and techniques. They must be willing to discard their current tool sets and embrace new, more powerful tool sets as they become available. Big data engineers need to have a natural curiosity and a desire to learn about the continuously changing open source landscape."
    },
    {
      question: "Data Analyst",
      answer:"We are looking for a passionate certified Data Analyst. The successful candidate will turn data into information, information into insight and insight into business decisions.",
      answer2:"Data analyst responsibilities include conducting full lifecycle analysis to include requirements, activities and design. Data analysts will develop analysis and reporting capabilities. They will also monitor performance and quality control plans to identify improvements."
    }
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  const date = new Date();
  date.setDate(date.getDate() - 30);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  const currentDate = `${day}-${month}-${year}`;

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {currentDate}<br/><br/>{faq.answer}<br/><br/>{faq.answer2}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1/>
      <DecoratorBlob2 />
    </Container>
  );
};

export default Careers