import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import Hero from "../components/hero/Hero.js";
import Stats from "../components/features/Stats";
import WhyUs from "../components/features/WhyUs";
import Values from "../components/features/Values.js";
import Services from "../components/cards/Services";
import Footer from "../components/footers/Footer";
import Testimonial from "../components/testimonials/Testimonials";
import BottomBanner from "../components/cta/Bottom-Banner";
import FAQ from "../components/faqs/FAQ.js";
import prototypeIllustrationImageSrc from "../images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const TrustekLandingPage = () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Stats/>
      <WhyUs
        heading= "Why Trustek?"
        description="Because we deliver!"
      />
      <Services
        headingHtmlComponent={
          <>
            We provide wide range of <HighlightedText>Services.</HighlightedText>
          </>
        }
      />
      <Values
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        description="We value expertise and maintain a high level of technical knowledge and experience to provide clients with accurate advice and effective solutions."
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We assure that the services provided are ethically correct with utmost quality.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            heading: "Amazing Experience",
            quote:
              "Extremely satisfied by the way Harsha has handled our business.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc."
          },
          {
            stars: 5,
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "The consultants Trustek has provided are experts in the feilds who helped us tranform our business with technological solutions.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC"
          }
        ]}
      /> */}
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "What services do we provide ?",
            answer:
              "Please visit the services page for more details."
          },
          {
            question: "How long do you usually take to respond ?",
            answer:
              "We respond the same business day most of the time but the turnaround time shouldn't be more than 2 business days."
          },
        ]}
      />
      <BottomBanner/>
      <Footer />
    </AnimationRevealPage>
  );
}

export default TrustekLandingPage