import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderSection from "../components/headers/light.js";
import Footer from "../components/footers/Footer";
import ContactForm from "../components/forms/ContactForm";
import Offices from "../components/cards/Offices.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

const ContactUsPage = () => {
  return (
    <AnimationRevealPage>
      <HeaderSection />
      <ContactForm />
      <Offices
        cards={[
          {
            title: "New Jersey",
            description: (
              <>
                <Address>
                  <AddressLine>4301 US-1,</AddressLine>
                  <AddressLine>Suite #125,</AddressLine>
                  <AddressLine>Monmouth Junction, NJ 08852</AddressLine>
                </Address>
                <Email>hr@trustek.com</Email>
                <Phone>Phone: +1 (732) 393-1313</Phone>
                <Phone>Fax: +1 (866) 294-1314</Phone>
              </>
            )
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};

export default ContactUsPage